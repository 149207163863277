import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllRequests = () => {
  return ApiService.get("requests");
};

/**
 * GET request to fetch data by Id
 * @param request_id
 * @returns {*}
 */
export const getRequestById = (request_id) => {
  return ApiService.get("requests", request_id);
};

/**
 * GET request to fetch data by subEvaluation_id
 * @param subEvaluation_id
 * @returns {*}
 */
export const getRequestBySubEvaluationId = (subEvaluation_id) => {
  return ApiService.get("requests/sub-evaluation", subEvaluation_id);
};

/**
 * GET request to fetch data by user id
 * @param userId
 * @returns {*}
 */
export const getByUserId = (userId) => {
  return ApiService.get("requests/user", userId);
};

/**
 * GET request to fetch data with oldest request trackings
 * @returns {*}
 */
export const getRequestWithOldestRequestTracking = () => {
  return ApiService.get("requests/tracking/oldest");
};

/**
 * GET request to fetch data by user id
 * @param userId
 * @returns {*}
 */
export const getWhereLastTrackingIsAcceptedOrRejectedByUserId = (userId) => {
  return ApiService.get("requests/tracking/latest/status/accepted-rejected/user", userId);
};

/**
 * POST request to push request request
 * @param request
 * @returns {*}
 */
export const createRequest = (request) => {
  return ApiService.post("requests", request);
};

//In case the request is accepted

/**
 * POST request to push request request
 * @param payload
 * @returns {*}
 */
export const acceptedRequest = (payload) => {
  return ApiService.post("requests/accept", payload);
};

//In case the request is declined

/**
 * POST request to push request request
 * @param payload
 * @returns {*}
 */
export const declinedRequest = (payload) => {
  return ApiService.post("requests/deny", payload);
};

export default {
  getAllRequests,
  getRequestById,
  getRequestBySubEvaluationId,
  createRequest,
  acceptedRequest,
  declinedRequest,
  getByUserId,
  getRequestWithOldestRequestTracking,
  getWhereLastTrackingIsAcceptedOrRejectedByUserId,
};
