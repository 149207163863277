<template>
  <div>
    <div class="card card-custom gutter-b">
      <!-- begin::Page title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Solicitud de corrección de notas
            <span class="d-block text-muted pt-2 font-size-sm"
              >Puede revisar la solicitud de correción de notas</span
            >
          </h3>
        </div>
      </div>
      <!-- end::Page title -->
      <div class="card-body px-1 px-sm-4">
        <!-- begin::evaluation and score correction justification -->
        <v-container>
          <v-row v-if="request.isLoadingRequestInfo">
            <v-col cols="12">
              <div style="height: 100px" class="white mt-3 mb-6">
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    Cargando datos de solicitud de corrección...
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="4"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <!-- begin::components that shows information about evaluation an subEvaluation for the score correction -->
            <v-col cols="12" lg="8" xl="9">
              <v-card class="rounded-lg elevation-0 pa-5" outlined>
                <p class="font-weight-bold text-h5">Detalles de perfil</p>
                <p class="font-weight-bold text-h6">
                  {{ evaluation.activity_type }}
                  <v-chip color="primary" small dense class="ml-2">{{
                    evaluation.percent_formatted
                  }}</v-chip>
                  <v-icon>mdi-circle-small</v-icon>
                  <span class="font-weight-medium text-h6">
                    {{
                      "evaluation" in subEvaluation
                        ? subEvaluation.evaluation.activity_category
                        : ""
                    }}</span
                  >
                </p>
                <p class="font-weight-medium text-body-1">
                  {{ evaluation.description }}
                </p>

                <div class="d-flex align-start">
                  <v-icon class="mt-3">mdi-subdirectory-arrow-right</v-icon>
                  <div class="ml-3 mt-3">
                    <p class="text-h6 font-weight-bold mb-1">
                      {{ subEvaluation.evaluation_type }}
                    </p>
                    <div class="d-flex mb-3">
                      <v-chip
                        color="primary"
                        small
                        class="mt-2 my-sm-auto d-flex justify-center"
                      >
                        <p
                          class="ma-0 text-center white--text text-body-1 font-weight-medium"
                        >
                          {{
                            `${subEvaluation.percent}% de ${evaluation.percent_formatted}`
                          }}
                        </p>
                      </v-chip>

                      <v-icon>mdi-circle-small</v-icon>

                      <p class="mb-0">
                        <span class="font-weight-bold grey--text text--darken-2"
                          >Fecha límite:</span
                        >
                        {{ subEvaluation.limit_date_formatted }}
                      </p>
                    </div>
                    <p
                      v-if="subEvaluation.description"
                      class="font-weight-medium text-body-1"
                    >
                      {{ subEvaluation.description }}
                    </p>
                    <p class="font-weight-medium text-body-1" v-else>
                      No hay descripción de actividad.
                    </p>

                    <v-btn
                      rounded
                      color="red"
                      dark
                      outlined
                      small
                      v-if="
                        subEvaluation.evaluation_instrument_document_path !=
                        null
                      "
                      @click="downloadEvaluationInstrument()"
                    >
                      <v-icon class="mr-1" color="red">mdi-file-pdf-box</v-icon>
                      <span class="font-weight-medium black--text"
                        >Instrumento de evaluación</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
            <!-- end::components that shows information about evaluation an subEvaluation for the score correction -->
            <!-- <v-divider vertical></v-divider> -->
            <v-col class="d-flex align-stretch" cols="12" lg="4" xl="3">
              <!-- begin::card that shows the justification for the score correction -->
              <v-card class="rounded-lg elevation-0 pa-5" outlined>
                <!-- begin:: justification card title -->
                <v-row>
                  <v-col class="d-flex justify-center mb-0" cols="12">
                    <v-avatar size="95" color="primary lighten-4">
                      <v-icon color="primary" large>mdi-account-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="px-5" cols="12">
                    <p class="font-weight-bold text-h6 text-center mb-1">
                      Docente de
                      {{
                        evaluation.subject_type === "Académica"
                          ? "asignatura"
                          : "módulo"
                      }}
                    </p>
                    <p class="font-weight-medium text-body-1 mb-0 text-center">
                      {{ evaluation.user_full_name }}
                    </p>
                  </v-col>
                </v-row>
                <!-- end:: justification card title -->
                <v-divider></v-divider>
                <!-- begin:: justification card body -->
                <v-row>
                  <v-col class="px-5">
                    <p class="font-weight-bold text-h6 mb-1 text-center">
                      {{ evaluation.subject }}
                    </p>
                    <p class="font-weight-medium text-body-1 mb-0 text-center">
                      {{ evaluation.grade }}, {{ evaluation.subject_type }}.
                    </p>
                  </v-col>
                </v-row>

                <!-- end:: justification card body -->
              </v-card>
              <!-- end::card that shows the justification for the score correction -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="pa-5 rounded-lg" outlined>
                <p class="font-weight-bold text-h5 mt-1 mb-2">
                  Detalles de solicitud
                </p>
                <p class="font-weight-medium text-body-1 mb-0">
                  <span
                    class="font-weight-bold text-body-1 grey--text text--darken-2 mb-0"
                    >{{ request.justificationType }}</span
                  >
                  <br />
                  {{ request.justificationContent }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- end::evaluation and score correction justification -->

        <!-- begin::table of corrections with pending scores to be approved -->
        <v-container class="mt-5">
          <v-row>
            <v-col cols="12" v-if="students.isLoading">
              <v-data-table
                item-key="name"
                class="elevation-1"
                loading
                loading-text="Cargando datos..."
              ></v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-data-table
                v-model="selected"
                :single-select="singleSelect"
                :headers="students.headers"
                :items="request.data.score_correction_details"
                mobile-breakpoint="800"
                item-key="id"
                show-select
                class="elevation-1 rounded-lg"
              >
                <!-- begin::table title -->
                <template v-slot:top>
                  <div class="px-5 pt-5 ma-0">
                    <p
                      class="ma-0 pa-0 text-center text-sm-left text-h5 font-weight-normal"
                    >
                      Correcciones de notas pendientes por aprobar
                    </p>

                    <p class="pa-0 mt-1 text-body-1 text-justify text-sm-left">
                      Al momento de procesar la solicitud si no han sido
                      seleccionados todos los estudiantes o no ha sido
                      seleccionado ninguno deberá especificar una justificación
                      y si todos han sido seleccionados no es necesario.
                    </p>
                  </div>
                </template>
                <!-- end::table title -->

                <!-- begin::table body, visual adjustments made to the body content-->
                <template v-slot:[`item.code`]="{ item }">
                  <!-- begin::student code column -->
                  <p class="ma-0 pr-4 pa-0 text-body-1 font-weight-bold">
                    {{ item.code }}
                  </p>
                  <!-- end::student code column -->
                </template>
                <template v-slot:[`item.full_name`]="{ item }">
                  <!-- begin::student name column -->
                  <p
                    class="text-capitalize ma-0 pa-0 text-body-1 font-weight-medium"
                  >
                    {{ item.full_name }}
                  </p>
                  <!-- end::student name column -->
                </template>
                <template v-slot:[`item.academic_group`]="{ item }">
                  <!-- begin:: student section column -->
                  <p class="ma-0 pr-4 pa-0 text-body-1 font-weight-bold">
                    {{ item.academic_group }}
                  </p>
                  <!-- end:: student section column -->
                </template>
                <template v-slot:[`item.current_score`]="{ item }">
                  <!-- begin::student current score (score to be replaced) -->
                  <v-chip color="red" outlined class="mr-4">
                    <p class="ma-0 pa-0 text-body-1 font-weight-bold">
                      {{ item.current_score }}
                    </p>
                  </v-chip>
                  <!-- end::student current score (score to be replaced) -->
                </template>
                <template v-slot:[`item.new_score`]="{ item }">
                  <!-- begin::student new score (score to be replaced with) -->
                  <v-chip color="green" outlined class="mr-4">
                    <p class="ma-0 pa-0 text-body-1 font-weight-bold">
                      {{ item.new_score }}
                    </p>
                  </v-chip>
                  <!-- end::student new score (score to be replaced with) -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- begin::finalize process btn -->
          <v-row class="d-flex justify-center">
            <!-- none selected -->
            <v-col
              cols="12"
              sm="12"
              class="d-flex justify-center"
              v-if="selected.length === 0"
            >
              <v-btn
                elevation="2"
                color="primary"
                @click="
                  howManySelected(
                    selected,
                    request.data.score_correction_details
                  )
                "
                >Continuar sin aprobar ninguno
                <v-icon right dark> mdi-chevron-right </v-icon></v-btn
              >
            </v-col>
            <!-- some selected -->
            <v-col
              v-if="
                selected.length > 0 &&
                selected.length < request.totalScoreCorrections
              "
              cols="12"
              sm="12"
              class="d-flex justify-center"
            >
              <v-btn
                elevation="2"
                color="warning"
                @click="
                  howManySelected(
                    selected,
                    request.data.score_correction_details
                  )
                "
                >Siguiente ({{ selected.length }} de
                {{ request.totalScoreCorrections }}
                seleccionados)
                <v-icon right dark> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>

            <!-- all selected -->
            <v-col
              v-if="selected.length === request.totalScoreCorrections"
              cols="12"
              sm="12"
              class="d-flex justify-center"
            >
              <v-btn
                elevation="2"
                color="primary"
                @click="
                  howManySelected(
                    selected,
                    request.data.score_correction_details
                  )
                "
                >Finalizar revisión y aprobar todos
                <v-icon right dark> mdi-send </v-icon>
              </v-btn>
            </v-col>

            <!-- TextArea for justification -->
            <v-col cols="12" md="8" v-if="justificate">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-textarea
                  required
                  v-model="justification"
                  :rules="textLength"
                  outlined
                  name="Justificacion"
                  label="Justificación"
                ></v-textarea>
                <v-row class="d-flex justify-center">
                  <v-col cols="auto">
                    <v-btn
                      :disabled="!valid"
                      color="primary"
                      @click="
                        validate(
                          selected,
                          request.data.score_correction_details,
                          request.data.user_id,
                          request.data.id
                        )
                      "
                    >
                      Finalizar revisión
                      <v-icon right> mdi-send </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <!-- end::finalize process btn -->
        </v-container>
        <!-- end::table of corrections with pending scores to be approved -->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SubEvaluationCardJustInfo from "@/components/elements/evaluation/SubEvaluationCardJustInfo.vue";
import requestRepository from "@/repositories/requestRepository";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ScoreCorrection",
  title: "Revisión de solicitud de corrección de notas | GE ITR",
  components: {
    SubEvaluationCardJustInfo,
  },
  props: {
    r: {
      type: String,
    },
  },
  mounted() {
    this.getRequest(this.r);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Solicitudes", route: "requests" },
      {
        title: "Revisión de solicitud de corrección de notas",
        route: "score_correction",
      },
    ]);
  },
  data() {
    return {
      //what's going to be saved
      justification: "",
      score_students: [],

      //response data
      acceptResponse: {
        request_id: "",
        user_id: "",
        justification: "",
        score_students: [],
      },

      denyResponse: {
        request_id: "",
        user_id: "",
        justification: "",
      },

      //here's where the request info is saved
      request: {
        isLoadingRequestInfo: false,
        data: {},
        justificationType: "",
        justificationContent: "",
        totalScoreCorrections: "",
      },

      //here's where the evaluation info is saved
      evaluation: {},
      subEvaluation: {},

      //form validations for justification and state managing
      selected: [],
      valid: true,
      singleSelect: false,
      justificate: false,
      textLength: [
        (v) => !!v || "Por favor agregue una justificación",
        (v) =>
          v.length >= 5 || "La justificación debe contener 5 o más caracteres",
      ],

      /*here's where the students with score correction request
       are saved for later display at the table headers and body.*/
      students: {
        headers: [
          { align: "center", text: "Código", value: "code" },
          {
            align: "center",
            text: "Nombre",
            value: "full_name",
          },
          {
            align: "center",
            text: "Especialidad",
            value: "technical_group",
          },
          {
            align: "center",
            text: "Nota a reemplazar",
            value: "current_score",
          },
          {
            align: "center",
            text: "Reemplazo",
            value: "new_score",
          },
        ],
        data: [],
        isLoading: false,
      },
    };
  },
  methods: {
    //fetch request by id
    getRequest(request_id) {
      this.request.isLoadingRequestInfo = true;
      this.students.isLoading = true;
      requestRepository
        .getRequestById(request_id)
        .then(({ data }) => {
          this.request.data = data;
          this.request.totalScoreCorrections =
            this.request.data.score_correction_details.length;
          this.request.justificationType =
            this.request.data.request_reason.name;
          this.request.justificationContent =
            this.request.data.request_tracking_latest.justification;
          this.subEvaluation = data.sub_evaluation;
          this.evaluation = data.sub_evaluation.evaluation;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.request.isLoadingRequestInfo = false;
          this.students.isLoading = false;
        });
    },

    downloadEvaluationInstrument() {
      window.open(
        this.evaluationInstrumentURL + this.subEvaluation.idE,
        "_blank"
      );
    },

    howManySelected(selected, total) {
      if (selected.length === 0) {
        //not a single item was selected.
        this.justificate = true;
        return (this.denyRequest = true);
      }

      if (selected.length < total.length) {
        //only some items were selected
        this.justificate = true;
        return (this.denyRequest = false);
      }

      if (selected.length === total.length) {
        //all items were selected
        this.justificate = true;
        this.justification =
          "Todas las correcciones de nota solicitadas han sido aprobadas.";
        return (this.denyRequest = false);
      }
    },

    validate(selected, allItems, user_id, request_id) {
      if (this.$refs.form.validate()) {
        /*depending if there were not any single item selected at the table,
         one response or another will take place*/
        if (this.denyRequest) {
          //setting data in variables for the "deny" endpoint response

          this.denyResponse.request_id = request_id;
          this.denyResponse.user_id = this.currentUserPersonalInfo.id_user;
          this.denyResponse.justification = this.justification;

          this.aproveSelected(selected, allItems);

          //excecuting response
          this.saveNone();
        } else {
          //excecuting response
          this.saveAllOrSome(selected, allItems, user_id, request_id);
        }
      }
    },

    saveNone() {
      this.students.isLoading = true;
      //declined - post response
      requestRepository
        .declinedRequest(this.denyResponse)
        .then(({ data }) => {
          if (data.status) {
            //if everything goes according to plan, we'll redirect to other page.
            this.finishReviewConfirmation(data);
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "No ha sido posible guardar guardar los cambios, por favor vuelva a intentar.",
          });
        });
    },

    saveAllOrSome(selected, allItems, user_id, request_id) {
      this.students.isLoading = true;

      //setting data in variables for the "deny" endpoint response.
      //thse are set here in case all items at the table are selected.
      this.aproveSelected(selected, allItems);
      this.acceptResponse.request_id = request_id;
      this.acceptResponse.user_id = this.currentUserPersonalInfo.id_user;
      this.acceptResponse.score_students = this.score_students;

      //setting a default justification message if all items at the table were selected.
      if (this.justification.length === 0) {
        this.acceptResponse.justification =
          "Todas las correcciones de nota solicitadas han sido aprobadas.";
      } else {
        this.acceptResponse.justification = this.justification;
      }

      requestRepository
        .acceptedRequest(this.acceptResponse)
        .then(({ data }) => {
          if (data.status) {
            //if everything goes according to plan, we'll redirect to other page.
            this.finishReviewConfirmation();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "No ha sido posible guardar guardar los cambios, por favor vuelva a intentar.",
          });
        });
    },

    //setting the "approved" status for the items at the table.
    //mapping the full array and changing the status of the selected ones first.
    aproveSelected(selectedItems, allItems) {
      /*going through each item of the array and changing it's approved
       status if selected from "null" to "1".*/
      selectedItems.forEach((element) => {
        return Object.defineProperty(element, "approved", {
          value: 1,
        });
      });

      /*By this point, the selected ones have had their
      approved status changed. Now, changing the status for the unselected ones.*/
      this.denyUnselected(allItems); //jump to line 519
      this.score_students = allItems;
    },

    /*Mapping the unselected items, finding them as the ones
    with an approved status of null.*/
    denyUnselected(allItems) {
      /*going through each item of the array and changing it's approved
       status if selected from "null" to "0" just if it's approved
       status is "null".*/
      allItems.forEach((item) => {
        if (item.approved !== 1) {
          return Object.defineProperty(item, "approved", { value: 0 });
        }
      });
    },

    finishReviewConfirmation() {
      Swal.fire({
        title: `Los cambios se guardaron exitosamente.`,
        icon: "success",
        width: 550,
        allowOutsideClick: false,
        confirmButtonColor: "#0abb87",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("correction_notes_extension_of_profiles_request");
        }
      });
    },

    //picks a random color given the id number of an item
    orderedColors() {
      return this.colors.sort(() => Math.random() - 0.5);
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),

    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL + "/sub-evaluations/evaluation-instrument/";
    },
  },
};
</script>
